import Mmenu from "mmenu-js";
import "../styles/menu.css";

document.addEventListener("DOMContentLoaded", () => {
  const menu = new Mmenu("#menu", {
    offCanvas: {
      page: {
        selector: "#page",
      },
      position: "right-front",
    },
    theme: "dark",
  });

  const api = menu.API;
  const open = document.getElementById("open-mobile-menu");
  const close = document.getElementById("close-mobile-menu");

  open.addEventListener("click", () => api.open());
  close.addEventListener("click", () => api.close());
});

const teamList = document.getElementById("team-list");
const teamListHover = teamList.previousElementSibling;

teamListHover.addEventListener("click", () => {
  teamList.classList.toggle("active");

  if (teamList.classList.contains("active")) {
    teamListHover.setAttribute("aria-expanded", "true");
  } else {
    teamListHover.setAttribute("aria-expanded", "false");
  }

  document.addEventListener("click", (e) => {
    if (!teamList.contains(e.target) && !teamListHover.contains(e.target)) {
      teamList.classList.remove("active");
    }
  });
});
